import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplyDiscountOnTotal,
  HandelLinkCustomer,
  HandelSalesman,
} from "../Redux/Reducer";
import DataService from "../services/requestApi";
import { Label } from "reactstrap";
import Swal from "sweetalert2";
import AddCustomerModal from "./AddCustomerModal";

const LinkCustomer = ({
  CustomerModal,
  setCustomerModal,
  alluser,
  LinkAMount,
  getFocus,
  GetCustomertolink,
  sendTransection
}) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const totalamount = displayData.reduce((sum, item) => sum + item.newPrice, 0);
  const [pricemodal, setPricemoadl] = useState(false);
  const [Userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [InvoicDicaount, setInvoicDicaount] = useState("");
  const [discpunttype, setDiscounttype] = useState("per");
  const customer = useSelector((state) => state.Reducer.LinkCustomer);
  const dispatch = useDispatch();
  const { tnc} =
  JSON.parse(localStorage.getItem("Store_data"));
  // const [concid, setconcid] = useState("")
  // const [salsemanId, setsalsemanId] = useState("")
  // const [data, setdata] = useState('')
  const inputRef = useRef(null);
  const ManagerFocus = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [activeKey, setActiveKey] = useState("Link Customer");
  const payload = {
    type: discpunttype,
    amount: InvoicDicaount,
  };
  const Addcustomer = (item) => {
    const payload = {
      type: "per",
      amount: item.discount_percent,
    };
    console.log("this selected user", item);
    dispatch(ApplyDiscountOnTotal(payload));
    dispatch(HandelLinkCustomer(item));
    setCustomerModal(false);
  };

  useEffect(() => {
    if (CustomerModal) {
      // Focus on the input element when the modal is opened
      inputRef.current.focus();
    }
  }, [CustomerModal]);

  const invoicDiscountInputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "p" || (e.key === "P" && CustomerModal)) {
        if (CustomerModal) {
          setDiscounttype("per");
          setInvoicDicaount("");
          invoicDiscountInputRef?.current.focus();
        }
      } else if (e.key === "a" || e.key === "A") {
        if (CustomerModal) {
          setDiscounttype("Amt");
          setInvoicDicaount("");
          invoicDiscountInputRef?.current.focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [CustomerModal]);

  useEffect(() => {
    if (activeKey === "Invoice Disount") {
      // Focus on the Invoice Discount input when the tab is clicked
      invoicDiscountInputRef.current.focus();
    }
  }, [activeKey]);

  // const GetSalesman = async () => {
  //     try {
  //         const response = await DataService.GetSalesmane(saasId, storeId, concid, salsemanId)
  //         console.log("this salse mans", response.data.data)
  //         setdata(response.data.data)
  //         dispatch(HandelSalesman(response.data.data))
  //     } catch (error) {
  //         console.log("this catch error", error)
  //         Swal.fire({
  //             title: "Sales Man Not Found",
  //             icon: "error",
  //             timer: 1000
  //         })
  //     }
  // }

  const SubmitDiscount = (e) => {
    e.preventDefault();
    setCustomerModal(false);
    if(tnc == "False"){
    if (payload.type == "per" && payload.amount > 20) {
      return setPricemoadl(true);
    }
    const GetPer = (payload.amount * 100) / totalamount;
    if (payload.type == "Amt" && GetPer > 20) {
      return setPricemoadl(true);
    }
  }
    dispatch(ApplyDiscountOnTotal(payload));
    setInvoicDicaount("");
  };

  useEffect(() => {
    const handleTabKeyPress = (e) => {
      if (e.key == "A" && e.shiftKey) {
        setModalShow(true);
      }
      if (e.key === "L" && e.shiftKey) {
        if (CustomerModal) {
          setActiveKey("Link Customer");
          setTimeout(() => {
            inputRef.current.focus();
          }, 500);
        }
      }
      if (e.key === "I" && e.shiftKey) {
        if (CustomerModal) {
          setActiveKey("Invoice Disount");
        }
      }
    };

    window.addEventListener("keydown", handleTabKeyPress);

    return () => {
      window.removeEventListener("keydown", handleTabKeyPress);
    };
  }, [setActiveKey, CustomerModal]);

  const CheckManger = async () => {
    try {
      const response = await DataService.CheckManger(Userid, password);
      console.log("check manger", response);
      if (response.data.status) {
        if (response.data.data.user_type == "MANAGER") {
          sendTransection(`Invoice Disount`,displayData);
          dispatch(ApplyDiscountOnTotal(payload));
          setInvoicDicaount("");
          setPricemoadl(false);
          setTimeout(() => {
            getFocus();
          }, 500);
        }
      } else {
        Swal.fire({
          title: "You are Not Allow",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Catch Error", error);
    }
  };
  useEffect(() => {
    if (pricemodal) {
      setTimeout(() => {
        ManagerFocus.current.focus();
      }, 500);
    }
  }, [pricemodal]);
  return (
    <>
      <Modal
        show={CustomerModal}
        onHide={() => {
          setCustomerModal(false);
          setInvoicDicaount("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Available Discounts</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0.3rem" }}>
          <Tabs
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
            defaultActiveKey="Link Customer"
            id="uncontrolled-tab-example"
            variant="pills"
            className="mb-3  fw-bold"
            fill
            style={{
              background: "#FFF",
              boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Tab
              className="fw-bold"
              eventKey="Link Customer"
              title="Link Customer (Shift + L )"
            >
              {/* <Form > */}
              <Row className="m-0">
                <Button
                  onClick={() => setModalShow(true)}
                  className="btn-outline-dark bg-primary-subtle fw-medium w-75 mx-2"
                >
                  Add Customer (Shift + A )
                </Button>
              </Row>
              <Row className="mt-3" style={{ margin: "40px 0px 0px -10px" }}>
                <Col>
                  <div>
                    <Form.Control
                      list="browsers"
                      name="browser"
                      id="browser"
                      ref={inputRef}
                      type="number"
                      onChange={(e) => {
                        const selectedUserName = e.target.value;
                        // console.log("value", e.target.value)
                        const selectedUser = alluser?.find(
                          (user) => user.mobile_number === selectedUserName
                        );
                        if (selectedUser) {
                          // setInvoicDicaount(item.discount_percent)
                          Addcustomer(selectedUser);
                        }
                      }}
                      style={{ width: "88%" }}
                      placeholder="Enter Customer Mobile Number"
                    ></Form.Control>

                    <datalist id="browsers">
                      {alluser &&
                        alluser.map((item, index) => {
                          return (
                            <option key={index} value={item.mobile_number}>
                              {item.name} - {item.card_number}
                            </option>
                          );
                        })}
                    </datalist>
                  </div>
                  <div>
                    {customer && (
                      <>
                        <span>{customer.name}</span>
                        <span className="mx-4">{customer.mobile_number}</span>
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              {/* </Form> */}
            </Tab>

            <Tab
              eventKey="Invoice Disount"
              title="Invoice Discount (Shift + I )"
            >
              <Form onSubmit={(e) => SubmitDiscount(e)}>
                <Row style={{ margin: "40px 0px 0px -10px" }}>
                  <Col sm={5} style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      checked={discpunttype === "per"}
                      onChange={() => {
                        setDiscounttype("per");
                      }}
                      style={{ height: "25px", width: "25px" }}
                      onFocus={() => {
                        // Clear the input value when it receives focus
                        setInvoicDicaount("");
                      }}
                    />
                    <div
                      className="fw-bold"
                      style={{
                        height: "50px",
                        width: "90px",
                        margin: "-30px 0px 0px 40px",
                      }}
                    >
                      %(P)
                    </div>
                  </Col>
                  <Col style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      checked={discpunttype === "Amt"}
                      onChange={() => {
                        setDiscounttype("Amt");
                      }}
                      style={{ height: "25px", width: "25px" }}
                      onFocus={() => {
                        // Clear the input value when it receives focus
                        setInvoicDicaount("");
                      }}
                    />
                    <div
                      className="fw-bold"
                      style={{
                        height: "50px",
                        width: "90px",
                        margin: "-30px 0px 0px 40px",
                      }}
                    >
                      Amount(A)
                    </div>
                  </Col>
                </Row>
                <Row style={{ margin: "40px 0px 0px -10px" }}>
                  <Col>
                    <Form.Control
                      ref={invoicDiscountInputRef}
                      type="number"
                      value={InvoicDicaount}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        if (discpunttype === "per") {
                          // If the discount type is 'Percentage', limit the value to 100
                          if(inputValue > 100){
                            setInvoicDicaount(100);
                          }else{
                            setInvoicDicaount(inputValue)
                          }
                        } else {
                          // If the discount type is 'Amt', you can set the value without restriction
                          if (inputValue > LinkAMount) {
                            setInvoicDicaount(LinkAMount);
                          } else {
                            setInvoicDicaount(inputValue);
                          }
                        }
                      }}
                      style={{ width: "88%" }}
                      placeholder="Enter Discount Percentage"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form>

              {/* <Button onClick={() => {
                         
                        }} variant="primary" className='mt-2' >
                            Apply Discount
                        </Button> */}
            </Tab>
            {/* <Tab eventKey="salseman" title="Link Salesman" >
                        <Form.Control onChange={(e) => { setconcid(e.target.value) }} className='mt-5' type="text" placeholder="Enter Conc Id" />
                        <Form.Control onChange={(e) => { setsalsemanId(e.target.value) }} className='mt-1' type="text" placeholder="Enter Sales Man Id" />
                        <div><span>{data.sales_man_name}</span></div>
                        <Button onClick={() => {
                            GetSalesman()
                        }} variant="primary" className='mt-2' >
                            Link Salesman
                        </Button>
                    </Tab> */}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {activeKey === "Invoice Disount" ? (
            <Button
              variant="primary"
              className="text-white"
              onClick={(e) => {
                setCustomerModal(false);
                setInvoicDicaount("");
                SubmitDiscount(e);
              }}
            >
              Apply&Close
            </Button>
          ) : (
            <Button
              variant="primary"
              className="text-white"
              onClick={() => {
                setCustomerModal(false);
                setInvoicDicaount("");
              }}
            >
              Close (Esc)
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* <----------------PRICE OVERRIDE MODAL--------------------> */}
      <Modal
        show={pricemodal}
        onHide={() => {
          setPricemoadl(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Check Manger</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row style={{ margin: "40px 0px 0px -10px" }}>
              <Col>
                <Form.Control
                  type="text"
                  ref={ManagerFocus}
                  onChange={(e) => setUserid(e.target.value)}
                  style={{ width: "88%" }}
                  placeholder="Enter User Id"
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ width: "88%" }}
                  placeholder="Enter Password"
                ></Form.Control>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPricemoadl(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={CheckManger}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <AddCustomerModal
        GetCustomertolink={GetCustomertolink}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default LinkCustomer;
