import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Table } from "react-bootstrap/esm";
import DataService from "../../services/requestApi";
import Export from "react-data-table-component";
import { Button, Form } from "react-bootstrap"
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
export const TenderReportModal = ({ show, setOpenTenderReportModal }) => {
  //   console.log("data", data);
  const [tenderData, setTenderData] = useState([]);

  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const business_date = JSON.parse(localStorage.getItem("business_date"));
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, 0);
  const day = String(currentDate.getDate()).padStart(2, 0);
  const formatDate = `${year}-${month}-${day}`;
  const [date, setDate] = useState(business_date);
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const formattedDate = new Date(inputDate).toLocaleDateString("en-CA");
    setDate(formattedDate);
  };
  const body = {
    saas_id: saasId,
    store_id: storeId,
    business_date: date,
  };
  const getTenderReport = async () => {
    try {
      const response = await DataService.TenderReport(body);
      const data = response.data;
      setTenderData(data.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getTenderReport();
  }, [date]);

  const downloadCSV = (data) => {
    // Check if data is not null and has at least one row
    if (data && data.length > 0) {
      const csvData = data
        .map((row) => Object.values(row).join(","))
        .join("\n");
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Tender.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      // Provide feedback or handle the case where there is no data to download
      Swal.fire({
        title: "No data available for download.",
        icon: "info",
        timer: 1500,
      });
      // You can also show a message to the user if needed.
    }
  };
  // const actionsMemo = React.useMemo(
  //   () => <Export onExport={() => downloadCSV(tenderData)} />,
  //   []
  // );
  const headers = [
    { label: "Tender Name", key: "tender_name" },
    { label: "Type", key: "type" },
    { label: "Customer IQD", key: "total_customer_iqd" },
    { label: "Customer USD", key: "total_customer_usd" },
    { label: "Total Amount", key: "total_amount" }
];
  return (
    <Modal
      show={show}
      fullscreen={true}
      onHide={() => setOpenTenderReportModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Tender Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {/* <Button onClick={() => downloadCSV(tenderData)}>Export</Button> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
      <CSVLink
        className="text-decoration-none bg-primary text-white p-2 rounded"
         filename={`tender.csv`} 
        data={tenderData} headers={headers}>
  Download CSV
</CSVLink>
          <input
            type="date"
            onChange={handleDateChange}
            style={{
              appearance: "none",
              WebkitAppearance: "none",
              MozAppearance: "none",
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "4px",
              outline: "none",
              width: "500px",
            }}
            value={date}
          />
          <h3> Tender Report of {date}</h3>
        </div>

        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Tender Name</th>
              <th>Customer IQD</th>
              <th>Customer USD</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
          {tenderData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.type}</td>
                <td>{item.tender_name}</td>
                <td>{item.total_customer_iqd}</td>
                <td>{item.total_customer_usd}</td>
                <td>{item.total_amount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
