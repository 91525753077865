import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField ,InputLabel,Select,MenuItem,FormControl} from '@mui/material';
import DataService from '../../services/requestApi';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';

const AddSalesmanMadal = ({ open, setAddSalesMan, SalesManData }) => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [stores, setStores]= useState([])
  const [data, setData] = useState({
    saas_id:saasId,
    store_id:"",
    sales_man_id:"",
    sales_man_name:"",
    status:"",
    commission:"",
    conc_id:"",
  })

  // API call for update department
  const AddSalesMan = async () => {
    try {
      if(data.sales_man_id && data.sales_man_name && data.conc_id && data.status){
        const response = await DataService.AddSalesMan(data);
        console.log(response)
        if (response.data.status) {
          SalesManData();
          setAddSalesMan(false);
          Swal.fire({
              title:"New Sales Man Added Successfully",
              icon:"success",
              timer:2000
          })
        }
        setData('')
      }else{
        Swal.fire({
          title:"Please Fill All Field",
          icon:"info",
          timer:2000
        })
      }
    } catch (error) {
      console.log("Error:", error);
      Swal.fire({
        title:"New Sales Man ID Will Be Unique",
        icon:"error",
        timer:2000
    })
    }
  };
  
  //write code to fatch api 
  const getStoreid=async ()=>{
    try {
      const response = await DataService.GetStoreBySaasid(saasId)
      console.log(response.data.data)
      setStores(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    if(open){
      getStoreid()
    }
  }, [open])
  

  const handleonchange=(e)=>{
    console.log(e.target.name,e.target.value)
    setData({...data,[e.target.name]:e.target.value})
   }
  return (
    <div>
      <Modal isOpen={open} toggle={() => setAddSalesMan(!open)}>
        <ModalHeader>
          <BsArrowLeft
            onClick={() => setAddSalesMan(!open)}
            className="mouse-pointer"
          />
          Add Sales Man *
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form className="form-box" encType="Content-Type">
                <div className="d-flex flex-col" style={{ display: "flex", flexDirection: "column" }}>
                  <Col md={12}></Col>
                  <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data.sales_man_id}
                name='sales_man_id'
                onChange={handleonchange }
                label="Sales Man Id"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data.sales_man_name}
                name='sales_man_name'
                onChange={handleonchange }
                label="Sales Man Name"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data.conc_id}
                name='conc_id'
                onChange={handleonchange }
                label="Concept Id"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data.commission}
                name='commission'
                onChange={handleonchange }
                label="Commission"
                multiline
                required
                rows={1}
              />  
                <FormControl fullWidth>
      <InputLabel id="isdcode-label" style={{ width: "fit-content" }}>Select Store</InputLabel>
      <Select
        label="Select Store"
        labelId="isdcode-label"
        id="store_id"
        name="store_id"
        value={data.store_id}
        onChange={handleonchange}
      >
        <MenuItem value="">
          <em>Select store_id</em>
        </MenuItem>
        {stores && stores.map((el) => (
          <MenuItem key={el.store_id} value={el.store_id}>
            {el.store_id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
              <FormControl className='my-3'>
              <InputLabel id="isdcode-label" style={{width:"fit-content"}}>Select Status</InputLabel>
              <Select
                      label="ISD Code"
                      labelId="isdcode-label"
                      id="status"
                      name="status"
                      // value={formProfileData.isdcode}
                      onChange={handleonchange} 
                      required
                    >
                      <MenuItem value="">
                        <em>Select Status</em>
                      </MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="inActive">inActive</MenuItem>
                   </Select>
                   </FormControl>
                </div>
                <div className="mt-2">
                  <button
                    className=''
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={AddSalesMan}
                  >
                    Add SalesMan
                  </button>
                  <span
                    onClick={() => setAddSalesMan(!open)}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddSalesmanMadal;
