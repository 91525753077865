import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';
import DataService from '../../services/requestApi';
import Swal from 'sweetalert2';

const SalesManUpdateModal = ({ open, setOpenUpdate, row, SalesManData }) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [salesManName, setSalesManName] = useState("");
  const [concId, setConcId] = useState("");

  const data = {
    saas_id: saasId,
    store_id: storeId,
    conc_id: concId,
    sales_man_name: salesManName
  };
  useEffect(() => {
    if(row){
     setSalesManName(row.sales_man_name)
     setConcId(row.conc_id)
    }
  }, [row])
  
  // API call for update name
  const UpdateSalesman = async () => {
    try {
      if(data.conc_id && data.sales_man_name){
        const response = await DataService.SalesManUpdate(row.sales_man_id, data);
        if (response.data.status) {
          SalesManData();
          setOpenUpdate(false);
        }

      }else{
        Swal.fire({
          title:"please Fill All Feild",
          icon:"info",
          timer:2000
        })
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'sales_man_name') {
      setSalesManName(e.target.value);
    } else if (e.target.name === 'conc_id') {
      setConcId(e.target.value);
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => setOpenUpdate(!open)}>
        <ModalHeader>
          <BsArrowLeft
            onClick={() => setOpenUpdate(!open)}
            className="mouse-pointer"
          />
          Update Salse Man*
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form className="form-box" encType="Content-Type">
                <div className="d-flex flex-col" style={{ display: "flex", flexDirection: "column" }}>
                  <Col md={12}></Col>
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    name="sales_man_name"
                    value={salesManName}
                    onChange={handleChange}
                    label="SalesMan Name"
                    multiline
                    required
                    rows={1}
                  />
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    value={concId}
                    name="conc_id"
                    onChange={handleChange}
                    label="Concept id"
                    multiline
                    required
                    rows={1}
                  />
                </div>
                <div className="mt-2">
                  <button
                    className=''
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={UpdateSalesman}
                  >
                    Update
                  </button>
                  <span
                    onClick={() => setOpenUpdate(!open)}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
      </Modal>
    </div>
  );
};

export default SalesManUpdateModal;
