import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import DataService from "../../.././services/requestApi";
import Swal from "sweetalert2";
function StoreMasterModal(props) {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [countries, setCountries] = useState([]);
  const [userId, setUserId] = useState([]);
  const [StoreMasterData, setStoreMasterData] = useState({
    user_id: 123456,
    store_id: "store123",
    saas_id: " ",
    store_name: "   ",
    city: " ",
    state: " ",
    country: " ",
    address: " ",
    taxable: true,
    gst_code: " ",
    tnc: " ",
    hsn_code: " ",
    store_type: " ",
    exclusive_tax: 10.5,
    inclusive_tax: 5.5,
    store_logo: "logo_url",
    bank_account: "account_number",
    bank_ifsc: "ifsc_code",
    bank_branch: "branch_name",
    payment_qrcode: " ",
    receipt_format: 1,
    email: " ",
    phone_no: " ",
    check_inventory: "inventory_status",
  });
  console.log(props.show);
  //country and user id fetching:--------
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryOptions = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error(error.message);
      }
    };

    const fetchUserId = async () => {
      try {
        const response = await fetch(
          "http://103.139.59.207:8089/prod/api/v1/register/get-all-id/1/10001"
        );
        const { data } = await response.json();
        console.log("userid", data);
        const userIdData = data.map((user) => ({
          label: user.user_id,
          value: user.user_id,
        }));
        setUserId(userIdData);
        console.log(userId);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchUserId();
    fetchCountry();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreMasterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitStoreMasterData = async (e) => {
    e.preventDefault();
    try {
      const response = await DataService.StoreMaster(StoreMasterData);
      if (response.status === 200) {
        Swal.fire({
          title: "Details Added Successfully in Store Master",
          icon: "success",
          confirmButtonText: "Done",
          timer: 3000,
        });
        props.setModalShow(false);
      }
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Something Not Working ",
        icon: "error",
        confirmButtonColor: "red",
        confirmButtonText: "Retry",
      });
    }
  };

  //stylings
  const inputStyle = {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "12px",
    border: "1px solid #ddd",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const ButtonStyle = {
    width: "50%",
    borderRadius: "8px",
    background: "#0067CE",
    color: "#fff",
    padding: "12px",
    cursor: "pointer",
    border: "none",
    transition: "background 0.3s ease-in-out",
  };

  inputStyle["&:hover"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  ButtonStyle[":hover"] = {
    background: "#00509E",
  };

  inputStyle["&:focus"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
    outline: "none",
  };

  ButtonStyle[":focus"] = {
    outline: "none",
  };

  inputStyle["&:active"] = {
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  ButtonStyle[":active"] = {
    background: "#003366",
  };

  //-------------------------
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Store Master</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Card>
            <CardBody>
              <Form>
                <Row className="mt-2 container">
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter  Store Code"
                        style={inputStyle}
                        // onChange={handleInputChange}
                        // name="store_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Address"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="address"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Select City"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="city"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Select State"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="state"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={userId}
                        placeholder="Enter User ID"
                        style={inputStyle}
                        onChange={(selectedOption) =>
                          handleInputChange({
                            target: {
                              name: "user_id",
                              value: selectedOption ? selectedOption.value : "",
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Store Name"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="store_name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Saas ID"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="saas_id"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={countries}
                        placeholder="Select Your Country"
                        styles={{ height: "100px", width: "95%" }}
                        onChange={(selectedOption) =>
                          handleInputChange({
                            target: {
                              name: "country",
                              value: selectedOption ? selectedOption.value : "",
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter GST Code"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="gst_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter  HSN Code"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="hsn_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Store Type"
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="store_type"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        required={true}
                        placeholder="Enter Payment QR Code "
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="payment_qrcode"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="email"
                        placeholder="Enter email "
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="Phone number "
                        style={inputStyle}
                        onChange={handleInputChange}
                        name="phone_no"
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        type="textarea"
                        placeholder="Enter T&C"
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          boxShadow: "0 1px 12px rgba(36, 36, 39, .12)",
                          borderRadius: ".3rem",
                          overflow: "hidden",
                        }}
                        name="tnc"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center fw-bold">
                      <button
                        className="btn btn-secondary fw-bold"
                        style={ButtonStyle}
                        onClick={handleSubmitStoreMasterData}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default StoreMasterModal;
