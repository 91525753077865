import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import CustomNavbar from "./UiComponents/CustomNavbar";
import ItemLookupModal from "./UiComponents/ItemLookupModal";
import RegisterModal from "./UiComponents/RegisterModal";
import RegisterCloseModal from "./UiComponents/RegisterCloseModal";
import ModalPriceCheck from "./UiComponents/ModalPriceCheck";
import CheckPriceModal from "./UiComponents/CheckPriceModal";
import userround from "./Images/User Round.png";
import downarrow from "./Images/User Rounded.png";
import bag from "./Images/bag.png";
import note from "./Images/Document Add.png";
import crown from "./Images/Crown Line.png";
import SidebarModal from "./UiComponents/SidebarModal";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import { RiDashboard2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ClearDisplay, sendCommand } from "./Constant/Util";
import { AiOutlineShop } from "react-icons/ai";
const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalPrice, setModalPrice] = useState(false);
  const [modalCheckPrice, setCheckModalPrice] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [registerClose, setRegisterClose] = useState(false);
  const { openPriceCheckModel } = useSelector((state) => state.Reducer);

  const navigate = useNavigate();
  useEffect(() => {
    if (!openPriceCheckModel) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [openPriceCheckModel]);

  //handel Till Register Open
  const { saasId, storeId, registerId, userType, userName } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const [user, setUser] = useState(userData);
  const HandeRegisterOpen = async (Id) => {
    const reg = Id ? Id : registerId;
    try {
      const data = {
        register_id: reg,
        store_id: storeId,
        saas_id: saasId,
        cashier_id: userName,
      };
      if (userType == "MANAGER" || userType == "ALL") {
        return Swal.fire({
          title: "Only Cashier Can Open Register",
          icon: "error",
     
        });
      }
      const response = await DataService.TillRegisterOpen(data);
      console.log(response);
      if (response.data.status) {
        const updatedUser = { ...user, registerId: Id };
        if (
          response.data.message !== "Register is already open" &&
          response.data.message !== "Store is still closed or not created yet"
        ) {
          localStorage.setItem("User_data", JSON.stringify(updatedUser));
        }
        setModalRegister(false);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
      }
    } catch (error) {
      console.log("this catch", error.response.data);
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
    }
  };

  //Till Regester Close
  const handelRegiterClose = async () => {
    try {
      if (userType == "MANAGER" || userType == "ALL") {
        return Swal.fire({
          title: "Only Cashier Can Close Register",
          icon: "error",
        
        });
      }
      const response = await DataService.TillRegisterClose(
        saasId,
        storeId,
        registerId
      );

      console.log("this console response", response);
      if (response.data.status) {
        localStorage.clear();
        navigate("/login");
        Swal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
        
        });
      }
    } catch (error) {
      console.log("close", error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);
  const handleKeyPress = (e) => {
    if (e.keyCode === 68) {
      // 116 is the key code for F5
      e.preventDefault();
      navigate("/dashbord");
      // setCheckModalPrice(true)
    }
    if (e.keyCode === 117) {
      // 116 is the key code for F5
      e.preventDefault();
      setModalShow(true);
    }

    if (e.keyCode === 76) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/welcomesearch"); // Navigate to the other page
    }
    if (e.keyCode === 86) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/scan"); // Navigate to the other page
    }
    if (e.keyCode === 69) {
      e.preventDefault(); // Prevent the default browser behavior
      if(saasId == 1){
        navigate('/AddLoytaltiCutm')
      }else{
        navigate("/enrollform"); // Navigate to the other page

      }
    }
    if (e.keyCode === 82) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/return"); // Navigate to the other page
    }
    if (e.keyCode === 119) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/startoftheday"); // Navigate to the other page
    }
    if (e.keyCode === 120) {
      // 120 is the key code for F9
      e.preventDefault();
      setModalRegister(true);
    }
    if (e.keyCode === 121) {
      e.preventDefault(); // Prevent the default browser behavior
      navigate("/tilloptions"); // Navigate to the other page
    }
    if (e.keyCode === 122) {
      e.preventDefault();
      // handelRegiterClose();
      return  
    }
    if (e.keyCode === 123) {
      // 123 is the key code for F12
      e.preventDefault();
      navigate("/endofday");
    }
    if (e.key === "s" || e.key === "S") {
      navigate("/shopandbuy");
    }
  };

  // <---------------------------------------Diplay To Port ------------------------->
  const [selectedPort, setSelectedPort] = useState(null);
  const { DisplaPort } = useSelector((state) => state.Reducer);
  const [alluse, setallUse] = useState();
  useEffect(() => {
    if (DisplaPort.length > 0 && !selectedPort) {
      // For simplicity, selecting the first available port
      const firstPort = DisplaPort[0];
      console.log("Using port:", firstPort);
      setSelectedPort(firstPort);

      // Open the selected port
      openSelectedPort(firstPort);
    }
  }, [DisplaPort]);

  const openSelectedPort = async (port) => {
    try {
      await port.open({ baudRate: 9600 });
      console.log("Serial port opened successfully.");
    } catch (error) {
      console.error("Error opening serial port:", error.message);
    }
  };

  useEffect(() => {
    if (selectedPort) {
      // sendCommand("",selectedPort); // Replace 'clear' with the command to clear the screen
      ClearDisplay(selectedPort);
      setTimeout(() => {
        sendCommand("     Welcome To          vast Retail", selectedPort);
      }, 5000);
    }
  }, [selectedPort]);

  return (
    <div>
      <CustomNavbar onMenuButtonClick={toggleModal} />
      <hr className="m-0" />
      <Container>
        <center>
          <Row>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <Col key={index} md={4} className="my-4">
                <Card
                  style={{
                    width: "370px",
                    height: "15rem",
                    background:
                      index === 1
                        ? "#e07707"
                        : index === 2
                        ? "rgb(160, 255, 144)"
                        : index === 3
                        ? "#B0DDFD"
                        : index === 4
                        ? "#FFEBEA"
                        : index === 6
                        ? "rgb(239 227 141)"
                        : "#5F6F52",
                  }}
                >
                  <Link
                    to={`/${
                      index === 1
                        ? "welcomesearch"
                        : index === 2
                        ? "scan"
                        : index === 3
                        ? "enrollform"
                        : index === 4
                        ? "return"
                        : index === 6
                        ? "shopandbuy"
                        : "Dashbord"
                    }`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card.Body>
                      <div>
                        <Card.Subtitle
                          className="mb-3"
                          style={{ fontSize: "13px" }}
                        >
                          {index === 1
                            ? "CHECKOUT"
                            : index === 2
                            ? "CHECKOUT"
                            : index === 3
                            ? "ENROLLMENT"
                            : index === 4
                            ? "Return"
                            : index === 6
                            ? "Shop and Buy"
                            : "Dashbord"}
                        </Card.Subtitle>
                        <Card.Title style={{ fontSize: "20px" }}>
                          {index === 1
                            ? "Continue as a Loyalty member"
                            : index === 2
                            ? "Continue as a Visitor"
                            : index === 3
                            ? "Would you like to Signup"
                            : index === 4
                            ? "Return Item"
                            : index === 6
                            ? "Explore and purchase products"
                            : "Check Your Daliy Sales"}
                        </Card.Title>
                        <div className="d-flex align-items-center justify-content-center">
                          {index !== 5 ? (
                            <img
                              height="80px"
                              width="80px"
                              src={
                                index === 1
                                  ? crown
                                  : index === 2
                                  ? userround
                                  : index === 3
                                  ? note
                                  : index === 6
                                  ? bag
                                  : downarrow
                              }
                              alt={`icon-${index}`}
                            />
                          ) : (
                            <RiDashboard2Line style={{ fontSize: "90px" }} />
                          )}
                        </div>
                        <Card.Text
                          className="fst-italic  mb-3 my-4"
                          style={{ fontSize: "12px" }}
                        >
                          {index === 1
                            ? "View your privileges and start checkout"
                            : index === 2
                            ? "Start here"
                            : index === 3
                            ? "Signup to receive privileges, points and more"
                            : index === 4
                            ? "Return Your Items"
                            : index === 6
                            ? "Discover a variety of items for purchase"
                            : "Your Store DashBord"}
                        </Card.Text>
                        <Button
                          variant="none"
                          style={{
                            border: "solid grey 1px",
                            margin:
                              index === 2
                                ? "-80px 0px 0px 340px"
                                : index === 6
                                ? "-70px 0px 0px 340px"
                                : "-70px 0px 0px 340px",
                            position: "relative",
                            left: "-40px",
                          }}
                        >{`${
                          index === 1
                            ? "L"
                            : index === 2
                            ? "V"
                            : index === 3
                            ? "E"
                            : index === 4
                            ? "R"
                            : index === 6
                            ? "S"
                            : "D"
                        }`}</Button>
                      </div>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            ))}
          </Row>
        </center>
      </Container>

      <>
        <SidebarModal
          handelRegiterClose={handelRegiterClose}
          showModal={showModal}
          toggleModal={toggleModal}
          setModalRegister={setModalRegister}
          setRegisterClose={() => setRegisterClose(true)}
        />
        <ItemLookupModal show={modalShow} onHide={() => setModalShow(false)} />
        <RegisterModal
          HandeRegisterOpen={HandeRegisterOpen}
          show={modalRegister}
          onHide={() => setModalRegister(false)}
        />
        <RegisterCloseModal
          show={registerClose}
          onHide={() => setRegisterClose(false)}
        />
        <ModalPriceCheck
          show={modalPrice}
          onHide={() => setModalPrice(false)}
        />
      </>
    </div>
  );
};
export default Home;
