import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import DataTable, { createTheme } from "react-data-table-component";
// import { toast } from 'react-toastify';
// import { handleUploadItemRequest, handleUploadInventoryRequest } from "../../../redux/actions-reducers/ComponentProps/ComponentPropsManagement"
import moment from "moment";
// import { handleLoginRequest } from '../../../redux/actions-reducers/ComponentProps/ComponentPropsManagement';
// import { useSelector } from "react-redux";
import { BASEURL } from "../../services/http-common";
import DataService from "../../services/requestApi";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { TextField ,InputLabel,Select,MenuItem,FormControl} from '@mui/material';
const ProductUpload = () => {
  const [businessDate, setBusinessDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fileFlag, setFileFlag] = useState("item");
  const [priceFile, setPriceFile] = useState("price");
  const [csvFile, setCsvFile] = useState("");
  const fileInputRef = useRef(null);
  const [data, setData]=useState()
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [IsSubmiting, setIsSubmiting] = useState(false);
  /*    const saasId = useSelector(state => state.saasId); 
    const storeId = useSelector(state => state.storeId); */
  const [Storeid, setStoreid] = useState("");
  const [stores, setStores]= useState([])
  createTheme("solarized", {
    text: {
      // primary: '#268bd2',
      // secondary: '#2aa198',
    },
    background: {
      // default: 'var(--primary2)',
      default: "#ffffff",
    },
  });

  const columns = [
    {
      name: "File Name",
      selector: (row) => row.fileName,
    },
    {
      name: "Total Records",
      selector: (row) => row.totalRecord,
    },
    {
      name: "Total Inserted",
      selector: (row) => row.totalInserted,
    },
    {
      name: "Upload Type",
      selector: (row) => row.type,
    },
    {
      name: "Store ID",
      selector: (row) => row.storeId,
    },
    {
      name: "Date",
      selector: (row) => moment(row.businessDate).format("YYYY-MM-DD"),
    },
  ];

 

  const CheckType = () => {
    if (fileFlag == "item") {
      return `${BASEURL.ENDPOINT_URL}/item/upload-item-master-temp/${Storeid}`;
    }
    if (fileFlag == "Price") {
      return `${BASEURL.ENDPOINT_URL}/item/upload-item-price-store-temp/${Storeid}`;
    }
    if (fileFlag == "Promotion") {
      return `${BASEURL.ENDPOINT_URL}/item/upload-item-price-temp/${Storeid}`;
    }
    if (fileFlag == "BOGO") {
      return `${BASEURL.ENDPOINT_URL}/item/upload-bogo-temp/${Storeid}`;
    }
  };
  const handleUploadFile = async () => {
    if (Storeid == "")
      return Swal.fire({ title: "Please Select Store Id", icon: "info" });
    if (fileFlag && csvFile) {
      const formData = new FormData();
      formData.append("file", csvFile);
      // formData.append("saas-id", saasId);
      // formData.append("store-id", storeId);

      const apiUrl = CheckType();

      try {
        setIsSubmiting(true);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Response:", jsonResponse);
          Swal.fire({
            title: `${fileFlag} Update Successfully`,
            text:`Total Item Added ${jsonResponse.totalItemsAdded} from Total Record ${jsonResponse.totalRecords}`,
            icon: "success",
          });
          GetHistory()
          setStoreid("");
          setCsvFile(null);
          fileInputRef.current.value = "";
          setIsSubmiting(false);
          // toast.success("File uploaded successfully!");
        } else {
          Swal.fire({
            title: `${fileFlag} Not Update Please Check Your File Format`,
            icon: "error",
            timer: 2000,
          });
          setIsSubmiting(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsSubmiting(false);
        // toast.error("An error occurred. Please try again later.");
      }
    } else {
      Swal.fire({
        title: `Select .csv File Please`,
        icon: "error",
        timer: 2000,
      });
      // toast.error("Please select a file and an option.");
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const [progress, setProgress] = React.useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const GetHistory= async ()=>{
    try {
      const response = await DataService.GetCsvHistory()
      console.log(response.data.data)
      setData(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    GetHistory()
  }, [])
  //write code to fatch api 
  const getStoreid=async ()=>{
    try {
      const response = await DataService.GetStoreBySaasid(saasId)
      console.log(response.data.data)
      setStores(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    
      getStoreid()
    
  }, [])
  
  return (
    <>
      <div className="mx-5">
        <Row>
          <Col md={12} className="mt-2 mb-2">
            <FormGroup>
              <h3>
                <b>Mass Upload</b>
              </h3>
            </FormGroup>
          </Col>
          <Col md={12} className="d-flex justify-content-around">
            <div style={{ fontWeight: "bold" }}>
              <Form>
                <Row>
                  <Col md={12} className="d-flex  justify-content-around">
                    <FormGroup>
                      {`Business Date: ${moment(new Date()).format(
                        "DD-MMM-Y"
                      )}`}
                    </FormGroup>
                    <FormControl style={{width:"50%"}}>
      <InputLabel id="isdcode-label" style={{ width: "fit-content" }}>Select Store</InputLabel>
      <Select
        className='mb-3'
        label="Select Store"
        labelId="isdcode-label"
        id="store_id"
        name="store_id"
        // value={selectedStoreId}
        onChange={(e) => {
          setStoreid(e.target.value);
        }}
        required
      >
        <MenuItem value="">
          <em>Select store_id</em>
        </MenuItem>
        {stores && stores.map((el) => (
          <MenuItem key={el.store_id} value={el.store_id}>
            {el.store_id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
                  </Col>
                  {/* <Col md={12}>
                 
                  </Col> */}
                  {IsSubmiting ? (
                    <>
                      <Stack
                        sx={{ width: "100%", color: "grey.500" }}
                        className="my-4"
                        spacing={2}
                      >
                        <span>File Uploading...</span>
                        <LinearProgress
                          sx={{ height: "10px", borderRadius: "10px" }}
                          color="primary"
                        />
                      </Stack>
                    </>
                  ) : (
                    <Col md={12}>
                      <FormGroup>
                        <Card>
                          <CardBody>
                            <div className="d-flex mb-3 justify-content-around">
                              <div className="me-5">
                                <Input
                                  type="radio"
                                  className="me-2"
                                  id="item-radio"
                                  name="csvradio"
                                  onChange={(e) => {
                                    setFileFlag("item");
                                    setCsvFile("");
                                  }}
                                  checked={fileFlag === "item"}
                                />
                                <Label htmlFor="item-radio">Item</Label>
                              </div>

                              <div>
                                <Input
                                  type="radio"
                                  className="me-2"
                                  id="inventory-radio"
                                  name="csvradio"
                                  onChange={(e) => {
                                    setFileFlag("Promotion");
                                    setCsvFile("");
                                  }}
                                  checked={fileFlag === "Promotion"}
                                />
                                <Label htmlFor="inventory-radio">
                                  Promotion
                                </Label>
                              </div>
                              <div>
                                <Input
                                  type="radio"
                                  className="me-2"
                                  id="inventory-radio"
                                  name="csvradio"
                                  onChange={(e) => {
                                    setFileFlag("Price");
                                    setCsvFile("");
                                  }}
                                  checked={fileFlag === "Price"}
                                />
                                <Label htmlFor="inventory-radio">Price</Label>
                              </div>
                              <div>
                                <Input
                                  type="radio"
                                  className="me-2"
                                  id="inventory-radio"
                                  name="csvradio"
                                  onChange={(e) => {
                                    setFileFlag("BOGO");
                                    setCsvFile("");
                                  }}
                                  checked={fileFlag === "BOGO"}
                                />
                                <Label htmlFor="inventory-radio">
                                  BOGO Upload
                                </Label>
                              </div>
                            </div>
                            {/* <div className="d-flex flex-wrap my-auto">
                                                    <div className='mb-3 me-4'>
                                                        <Label>Upload CSV File <span className="text-red"> * </span></Label>
                                                        <div>
                                                            <label htmlFor="csvfile" className='upload-doc'>Upload CSV</label>
                                                            <input
                                                                type="file"
                                                                accept='.csv'
                                                                id="csvfile"
                                                                onChange={e => {
                                                                    setCsvFile(e.target.files[0])
                                                                }}

                                                            />
                                                            <small className='ms-1'>
                                                                {csvFile.name}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className='my-auto'>
                                                        <Button
                                                        className='btn btn-primary'
                                                            type='button'
                                                            onClick={(e) => handleUploadFile()}
                                                            style={{ backgroundColor: "", border: "none" }}> Submit </Button>
                                                    </div>
                                                </div> */}
                            <div
                              className="d-flex"
                              style={{ marginBottom: "20px" }}
                            >
                              <label
                                className="mt-2"
                                htmlFor="fileInput"
                                style={{
                                  fontSize: "16px",
                                  marginRight: "10px",
                                }}
                              >
                                {fileFlag} Uplaod Csv:
                              </label>
                              <input
                                type="file"
                                accept=".csv"
                                id="csvfile"
                                valuref={fileInputRef}
                                onChange={(e) => {
                                  setCsvFile(e.target.files[0]);
                                }}
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                  borderRadius: "4px",
                                }}
                              />

                              <div className="my-auto">
                                <Button
                                  disabled={csvFile ? false : true}
                                  className=" ml-4"
                                  type="button"
                                  onClick={(e) => handleUploadFile()}
                                  style={{
                                    marginLeft: "30px",
                                    backgroundColor: "rgb(46, 69, 175)",
                                    border: "none",
                                  }}
                                >
                                  {" "}
                                  Submit{" "}
                                </Button>
                              </div>
                            </div>
                            {/* <Button onClick={()=>{downloadCSV(`${BASEURL.ENDPOINT_URL}/csv/itemreport/${businessDate}/${storeId}`,"ItemReport")}}  className=' mr-4' type='button' >Download Item Report Csv</Button>
    <Button className="mx-3" onClick={()=>{downloadCSV(`${BASEURL.ENDPOINT_URL}/csv/sfile/${businessDate}/${storeId}`,"Header")}} type='button' >Download Header Report Csv</Button>
    <Button onClick={()=>{downloadCSV(`${BASEURL.ENDPOINT_URL}/csv/tenderreport/${businessDate}`,"Tender")}}  className=' mr-4' type='button' >Download Tender Report Csv</Button>
         */}
                          </CardBody>
                        </Card>
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <DataTable
                          columns={columns}
                          data={data}
                          theme="solarized"
                          className="table-bordered"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProductUpload;
