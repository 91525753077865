import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataService from "../../services/requestApi";
import moment from "moment";
function LoylityCardReportModal(props) {


  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
  const [loylityCard, setLoylityCard]= useState('')
  const GetAllLoylityredemption=async()=>{
    try {
      const response =await DataService.GetLoylityCardRedemption(saasId,storeId,date)
      console.log("Get loylity issue", response.data)
      setLoylityCard(response.data.data)
  
  
    } catch (error) {
      console.log("error", error)
    }
  
  }


  useEffect(() => {
    if(date){
      GetAllLoylityredemption()
    }
  }, [date])


    const columns = [
        {
            name: "SHOPNAME", 
            selector:(row)=>row.store_name,
            sortable: true
          },
        {
          name: "BILL Date",
          selector: (row) => row.business_date,
          sortable: true
        },
      
        {
          name: "BILL NUMBER",
          selector:(row)=>row.invoice_number,
          sortable: true,
        },
        {
          name: 'USER ID',
          selector: (row) => row.cashier_id ,
          sortable: true
        },
        // {
        //   name:  'POS ID',
        //   selector: (row) =>row.total_item_qty,
        //   sortable: true
        // },
        // {
        //   name:  'BILL QTY',
        //   selector: (row) =>row.atv,
        //   sortable: true
        // },
        {
          name:  'Total Item Net',
          selector: (row) => row.total_item_net,
          sortable: true
        },
        {
            name:  'LC NUMBER',
            selector: (row) =>row.card_number,
            sortable: true
          },
          {
            name:  'TELEPHONE',
            selector: (row) => row.customer_mobile,
            sortable: true
          },
          {
            name: 'STAFF ID',
            selector: (row) => row.total_net_value ,
            sortable: true
          },
          {
            name:  'CUST NAME',
            selector: (row) =>row.customer_name,
            sortable: true
          },
      ];
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
          <Modal.Header className="container" closeButton>
        <Modal.Title>Loylity Card Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className='container'>
                    <Form.Control
          type="date"
          className='w-50'
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e)=>{setDate(e.target.value)}}
        />

{loylityCard && (
  <DataTable
    noHeader
    defaultSortField="id"
    defaultSortAsc={false}
    columns={columns}
    responsive={true}
    fixedHeader={true}
    data={loylityCard}
    pagination
    paginationServer
  />
)}

                 </Modal.Body>
    </Modal>
  );
}

export default LoylityCardReportModal;
