import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";

import Swal from "sweetalert2";
import GetUserModal from "./MangeAllModal/GetUserModal";

function UserMaster({ data, count, handlePageChange, GetUser }) {
  const [modalShow, setModalShow] = useState(false);
  console.log(data);

  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  const columns = [
    {
      name: "User Name",
      center: true,
      selector: (row) => row.party_name,
      cell: (row) => {
        return (
          <>
            <div>{row.user_name}</div>
          </>
        );
      },
    },

    {
      name: "Store Id",
      center: true,
      selector: (row) => row.store_id,
    },
    {
      name: "Store Name",
      center: true,
      selector: (row) => row.address,
    },

    {
      name: "Register Id",
      center: true,
      selector: (row) => row.register_id,
    },

    {
      name: "Country",
      center: true,
      selector: (row) => row.country,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="d-flex">
            <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                    
                    />
                  </div>
              <div>
                <MdEdit
                  size={22}
                  color="green"
                  className="mouse-pointer"
                  onClick={() => {
                    setModalShow((state) => !state);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        {" "}
        <Container>
          <DataTable
            columns={columns}
            responsive={true}
            fixedHeader={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangePage={(page) => handlePageChange(page)}
          />
        </Container>
      </div>
      <GetUserModal
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
        GetUser={GetUser}
      />
    </>
  );
}

export default UserMaster;
